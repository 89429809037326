<!--
 * @Description: 购物车确认
 * @Version: 1.0
 * @Autor: ziwei
 * @Date: 2021-08-20 11:27:26
 * @LastEditors: ziwei
 * @LastEditTime: 2021-08-23 17:28:52
-->
<template>
  <div>
    <div class="queren_t">
      <h3>{{this.$t('receiverAddress')}}：</h3>
      <a @click="goAddAds">+ {{this.$t('addAddress')}}</a>
      <div class="clear"></div>
    </div>
    <div class="queren_adds">
      <ul v-if="addArrList.length > 0">
        <li v-for="(v, i) in addArrList" :key="i">
          <div class="pc_fl">
            <div class="fl queren_adds_1">
              <label class="demo--label">
                <input
                  v-model="AddIndex"
                  :value="i"
                  class="demo--radio"
                  type="radio"
                  name="adds"
                  checked="checked"
                />
                <span class="demo--radioInput"></span>
              </label>
            </div>
            <div class="fl queren_adds_2">
              <h3>
                {{ v.receiverName }}<span>{{ v.receiverPhone }}</span>
                <i v-if="v.isDefault == 1">默认地址</i>
              </h3>
              <p>{{ v.region }} {{ v.recAddress }}</p>
            </div>
            <div class="clear"></div>
          </div>
          <div class="pc_fr queren_adds_3">
            <a @click="fixAdd(v.owid)">修改</a> |
            <a @click="delAdd(v.owid, i)">删除</a>
          </div>
          <div class="clear"></div>
        </li>
      </ul>
      <NullTip
        :tip="this.$t('noAddress')"
        :stl="'padding:10px'"
        v-else
      ></NullTip>
    </div>
    <!-- <div class="invoice-header">
      <span style="fontSize:0.09rem">发票抬头：</span>
    <el-select v-model="form.region" placeholder="请选择发票抬头">
      <el-option label="区域一" value="shanghai"></el-option>
      <el-option label="区域二" value="beijing"></el-option>
    </el-select>
    <br>
        <span style="fontSize:0.09rem">是否需要纸质发票：</span>
         <input type="checkbox" name="" id="" class="demo--checkboxInput">
    </div> -->
    <div class="queren_zf">
      <label v-for="(v, i) in payWay" :key="i">
        <input
          class="demo--radio"
          :value="v.val"
          type="radio"
          name="zf"
          v-model="selPayWay"
          @change="getChange(v.val)"
        />
        <span class="demo--radioInput"></span>{{ v.name }}
      </label>
    </div>

    <div class="queren_cp">
      <h3>{{this.$t('sureOder')}}：</h3>
      <div class="queren_cp_1">
        <table>
          <tr>
            <th></th>
            <th>{{this.$t('productInfo')}}</th>
            <th>{{this.$t('attribute')}}</th>
            <th>{{this.$t('unitPrice1')}}</th>
            <th>{{this.$t('quantity')}}</th>
            <th>{{this.$t('total')}}</th>
          </tr>
          <tr
            v-for="(v, i) in selectProduct"
            :key="i"
            @click="goProDt(v.productRefOwid)"
          >
            <td><img :src="IMGURL + v.productPic" /></td>
            <td>
              <h4>{{ v.productName || "" }}</h4>
              <p>{{ v.secondName || "" }}</p>
            </td>
            <td>{{ v.categoryName }}-{{ v.specsName }}</td>
            <td>
              <h4>￥{{ (v.specsPromotionprice).toFixed(2)}}</h4>
              <!-- <span>￥500.00</span> -->
            </td>
            <td>{{v.count }}</td>
            <td>￥{{ (v.specsPromotionprice * v.count).toFixed(2)}}</td>
          </tr>
        </table>
      </div>
    </div>
    <div class="queren_js">
      <div class="pc_fl">
        <span>{{this.$t('productTotal')}}：￥{{(getTlPrize).toFixed(2)}}元</span>
      </div>
      <div class="pc_fr">
         {{this.$t('payble')}}：￥{{(getTlPrize).toFixed(2)}}元<a @click="bckCart">{{this.$t('returnCart')}}</a
        ><a @click="goBuyNow">{{this.$t('placeOder')}}</a>
      </div>
      <div class="clear"></div>
    </div>
  </div>
</template>

<script>
import { myAddress, delAdd, createOrder,isCash ,buyNow} from "./model";
import { isForeign, showLayer, isEmpty, goTop } from "@/utils/com";
import { IMGURL } from "@/utils/constant";
import NullTip from "@/components/component/NullTip.vue";
import { mapState, mapActions, mapMutations } from "vuex";
export default {
  data() {
    return {
      IMGURL,
      //我的地址列表
      addArrList: [],
      //选择地址的index
      AddIndex: 0,

      //选择支付方式 0先支付 1后支付ß
      payWay: [
        { name: "直接支付", val: 0 },
        { name: "货到付款", val: 1 },
      ],
      selPayWay: 0,
      form:{
        region:''
      },
      payMethods:'',
      buyType:'',
      selectProduct:''
    };
  },
 
  computed: {
    
    // ...mapState("product", ["selectProduct"]),
    //获取总价格
    getTlPrize() {
      let num = 0;
      this.selectProduct.forEach((v) => {
        num += v.count * v.specsPromotionprice;
      });
      return num;
    },
    //获取地址
    getAddObj() {
      let a = this.addArrList[this.AddIndex];
      let obj = {};
      if (a) {
        obj = {
          recName: a.receiverName,
          recMobile: a.receiverPhone,
          recRegionId: a.recRegionId,
          recRegionPath: a.recRegionPath,
          recRegionName: a.region,
          recAddress: a.recAddress,
          recCountryCode: a.recCountryCode,
        };
      }
      return obj;
    },
    //购物车数据的主键
    itemList() {
      let arr = [];
      this.selectProduct.forEach((v) => {
        // arr.push
        console.log(v);
        arr.push({specsRefOwid:v.specsRefOwid,itemQuantity:v.itemQuantity});
        // console.log([{specsRefOwid:v.specsRefOwid,itemQuantity:v.itemQuantity}]);
      });
      return arr;
     
    },
  },
  async created() {
    this.selectProduct = this.$route.query.params && JSON.parse(this.$route.query.params)
    console.log(this.selectProduct);
    let res = await myAddress();
    if (res && Array.isArray(res)) {
      this.addArrList = res;
      let index = res.findIndex((v) => {
        return v.isDefault == 1;
      });
      this.AddIndex = index;
    }
    // 如果结果为false表明用户不是会员，不支持货到付款
     isCash().then(res=>{
      if(res.bean === false){
        this.payWay.pop()
      }
    })
  },
  methods: {
    ...mapMutations("product", ["setPayRs"]),
    // 提交订单
    async goBuyNow() {
       let arr = [];
       this.selectProduct.forEach((v) => {
        arr.push({specsRefOwid:v.owid,itemQuantity:v.count});
      });
      if (!this.getAddObj.recName) {
        showLayer("toast", "未选择收货地址");
        return;
      }
      let params = {
        orderDkAmount: 0,
        orderType: isForeign() ? 1 : 0, //国内还是国外
        orderPayType: this.selPayWay,
        orderCurrency: isForeign() ? 1 : 0,
        orderSourceType: 0, //订单来源0 是pc
        ...this.getAddObj,
        billType: 1, //0不开发票1电子发票2纸质发票
        itemList: arr,
      };
      let res = await buyNow(params);
     
      if (res) {
        this.setPayRs({ payResult: res });
        this.$router.push({path:"/pay",query:{info:JSON.stringify(res)}});
      }
      if(this.payMethods === 1){
        this.$router.push('/profile/order')
      }
    },
   
    //点击添加地址
    goAddAds() {
      this.$router.push("/address");
    },

    //点击删除
    async delAdd(owid, index) {
      let res = await delAdd({ owid });
      if (res) {
        this.addArrList.splice(index, 1);
      }
    },

    //修改地址
    fixAdd(owid) {
      this.$router.push({ path: "/address", query: { id: owid } });
    },

    //点击进入产品详情
    goProDt(owid) {
      this.$router.push({ path: "/productDetails", query: { proId: owid } });
    },

    //返回购物车
    bckCart() {
      this.$router.push("/profile/shoppingcart");
    },
    getChange(v){
      this.payMethods = v;
    }
  },
  components: {
    NullTip,
  },
};
</script>

<style>
  .el-select {
    margin-top: .2rem;
    width: 2rem;
  }
  .demo--checkboxInput {
    margin-top: .10rem;
    width: .09rem;
    height: .09rem;
  }
</style>
